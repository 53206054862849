import axios from 'axios';
import { AxiosRequestConfig } from "./services.types";

export const requester = async ({
  url,
  baseURL,
  method,
  headers,
  data
}: AxiosRequestConfig) => {

  const response = await axios({
    method: method,
    url: baseURL + url,
    headers,
    data,
  });

  return response;
};
