import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";
import { confirmSignUpUser, signUpUser } from "services/user";
import useResendToken from "hooks/useResendToken";

const SignupToken = () => {
  
  const [loader, setLoader] = useState(false);
  const {client, isClientDefined, signupData, signupInitialForm, handleSignupData} = useAppContext();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataPropsType>({resolver: yupResolver(schema)});

  useEffect(() => {

    if(!isClientDefined) navigate("/")

  }, [])

  async function submitForm(data: FormDataPropsType) {
    
    setLoader(true);

    const { token, requestId } = await confirmSignUpUser(client, data.validationCode, signupData.userId);
    if(!token || !requestId) { setLoader(false); return; } 

    handleSignupData({token, requestId});

    setLoader(false);
    
    navigate("/signup-password");

  }

  const {
    handleLoaderResend,
    setActiveTimer,
    loaderResend,
    labelResendButton,
    activeTimer
  } = useResendToken(30);
  
  async function handleResendToken(){
    
    handleLoaderResend(true);
    
    const {id} = await signUpUser(client, signupInitialForm.cnpj, signupInitialForm.cellphone, signupInitialForm.email, signupInitialForm.cardName);
    if(!id) { handleLoaderResend(false, true); return; } 
    
    handleSignupData({userId: id});
    handleLoaderResend(false);
    setActiveTimer(true);

  }

  function handleBackButton() {
    navigate("/signup-initial");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Cadastro"
        subTitle="Bem-vindo ao BIB Rewards"
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader},
        }}
      >
        <Form control={control} errors={errors} resendToken={{handleResendToken, loaderResend, labelResendButton, activeTimer}} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default SignupToken;
