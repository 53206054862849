import { createGlobalStyle } from "styled-components";

export const disabledOpacity = 0.7;

const GeneralStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
  }

  #root {
    height: 100%;
  }

  html {
    height: 100%;
  }

  body {
    background-color: #D9D9D9;
    height: 100vh;
    width: 100%;
  }

  p, h1, h2, h3, h4, h5, h7, i, blockquote, a, br, strong, em, hr, code, pre, ul, ol, li, dl, dt, dd, label, select, small, sub, sup, del, ins, input, mark, b, span {
    font-family: 'Roboto', sans-serif;
  }

  button {
    cursor: pointer;

    &:focus {
      opacity: 0.7;
    }

    &:not(:disabled) {
      &:hover {
        opacity: 0.95;
      }
    }
  }
  
  :disabled {
    opacity: ${disabledOpacity};
    cursor: not-allowed;
  }

  input::-webkit-calendar-picker-indicator{
    display: none;
  };

  input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
  }
`;

export default GeneralStyle;
