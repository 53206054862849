import { AxiosRequestConfig } from "./config/services.types";
import { getRequestParameters } from "./config/services.parameters";
import { validateOnlyNumber } from "utilities/Validators";
import { getTokenBanking } from "utilities/localStorage";
import { requester } from "./config/requester";
import { toast } from "react-toastify";
import { ClientType } from "global/types/application.type";

export const signUpUser = async (client: ClientType, document: string, phone: string, email: string, name: string) => {
    
    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: 'users/sign-up',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           fid: getRequestParameters(client).bankingParameters.fid,
           username: validateOnlyNumber(document),
           kind: 'customer',
           phone: `+55${validateOnlyNumber(phone)}`,
           email,
           name      
        },
    }

    try {
        const { data } = await requester(service);
        return data.user;
    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

}

export const verifyDocument = async (client: ClientType, documentNumber: string) => {
    
    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: 'accounts/verify-document',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           documentNumber      
        },
    }

    try {
        const { data } = await requester(service);
        return data;
    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

}

export const firstLogin = async (client: ClientType, accountId: string) => {
    
    const service: AxiosRequestConfig = {
        method: 'GET',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: `accounts/${accountId}/properties`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        }
    }

    try {
        
        const { data } = await requester(service);
        
        if(data.firstLogin){
            toast.error('CNPJ já cadastrado. Retorne para a página inicial e insira seu CNPJ e senha para acessar o portal. Se preferir utilize o recurso "Esqueci minha senha".');
            return false
        }

        return true

    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

}

export const patchSignupUser = async (client: ClientType, accountId: string, name: string) => {

    const service: AxiosRequestConfig = {
        method: 'PATCH',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: `accounts/${accountId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking()
        },
        data: {
           name,
           properties: { firstLogin: true }      
        },
    }

    try {
        await requester(service);
        return true;
    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

};

export const confirmSignUpUser = async (client: ClientType, token: string, userId: string ) => {
    
    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: `users/${userId}/confirm`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           fid: getRequestParameters(client).bankingParameters.fid,
           token    
        },
    }

    try {
        const { data } = await requester(service);
        return data;
    } catch(e:any) {
        
        if(e.response.data.code === "NotValidSecret"){
            toast.error('O código informado não é válido.');
        }else{
            toast.error('Opss... Algo deu errado!');
        }

        return false;
    }

}

export const registerPassword = async (client: ClientType, userId: string, requestId:string, token: string, password: string) => {

    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: `users/${userId}/register-password/${requestId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           fid: getRequestParameters(client).bankingParameters.fid,
           token,
           password    
        },
    }

    try {
        const { data } = await requester(service);
        return data;
    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

}

export const forgetPassword = async (client:ClientType, document: string) => {

    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: 'users/forget-password',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           fid: getRequestParameters(client).bankingParameters.fid,
           documentNumber: validateOnlyNumber(document),    
        },
    }

    try {
        const { data } = await requester(service);
        return data;
    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

}

export const forgetPasswordConfirm = async (client:ClientType, requestId:string, token: string) => {

    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: `users/forget-password/${requestId}/confirm`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           fid: getRequestParameters(client).bankingParameters.fid,
           token,    
        },
    }

    try {
        const { data } = await requester(service);
        return data;
    } catch(e:any) {
        
        if(e.response.data.code === "NotValidSecret"){
            toast.error('O código informado não é válido.');
        }else{
            toast.error('Opss... Algo deu errado!');
        }
        
        return false;
    }

}

export const registerNewPassword = async (client:ClientType, requestId: string, token: string, password: string) => {

    const service: AxiosRequestConfig = {
        method: 'POST',
        baseURL: getRequestParameters(client).bankingParameters.baseUrl,
        url: `users/forget-password/${requestId}/register`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getTokenBanking(),
        },
        data: {
           fid: getRequestParameters(client).bankingParameters.fid,
           token,
           password    
        },
    }

    try {
        const { data } = await requester(service);
        return data;
    } catch {
        toast.error('Opss... Algo deu errado!');
        return false;
    }

}