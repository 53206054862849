/* eslint-disable react-hooks/exhaustive-deps */
import { InputHTMLAttributes } from 'react';
import styled from 'styled-components'

import { FontSize } from 'global/styles/sizes.styles'


export interface InputPropsType {
  input: IptPropsType;
  label?: string;
  options: any[]
}

export interface IptPropsType extends InputHTMLAttributes<HTMLInputElement> {
  password?: boolean;
  change: Function;
}

const Select = (props: InputPropsType) => {
  const { label, input, options } = props;
  const { change, value } = input;

  return (
    <InputWrapper>
      <Label>{label}</Label>
      <SelectStyle
        onChange={(e) => change(e.target.value)}
        value={value}
      >
        {options.map(option => <option key={option} value={option}>{option}</option>)}
      </SelectStyle>
    </InputWrapper>
  )
}

export default Select

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const Label = styled.span``

const SelectStyle = styled.select`
  border: none;
  width: 100%;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-size: ${FontSize.medium};
  color: #000;
  padding: 8px 0;
  margin-top: 8px;
  border-bottom: 1px solid #000;
  background: transparent;

  ::placeholder {
    color: #566068
  }
`