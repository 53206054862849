import { FormRow } from "components/Layouts/FormLayoutStyles";
import InputText from "components/inputs/InputText";
import { Controller } from "react-hook-form";
import { FormPropsType } from "./schema";

export const Form = ({ control, errors, resendToken }: FormPropsType) => {
  return (
    <>
    <FormRow>
        <Controller
          control={control}
          name="validationCode"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                change: onChange,
                value: value,
                maxLength: 6,
              }}
              label="Código de Validação (recebido via SMS)"
              errorMessage={errors.validationCode?.message}
            />
          )}
        />
      </FormRow>
      <FormRow>
      <button 
        onClick={() => {resendToken.handleResendToken()}}
        disabled={resendToken.loaderResend || resendToken.activeTimer}
      >
        {resendToken.labelResendButton}
      </button>
    </FormRow>
    </>
  );
};
