import React from "react";
import { AppContextType, SignupData, SignupDataApiReturn, SignupInitialFormType } from "./types";
import { ClientType } from "global/types/application.type";

const AppContext = React.createContext({} as AppContextType);

type AppContextProviderPropsType = {
  children: JSX.Element[];
};

export function AppContextProvider({ children }: AppContextProviderPropsType) {
  const [client, setClient] = React.useState<ClientType>("Bib Infinity");
  const [hideClientForm, setHideClientForm] = React.useState(true);
  const [signupInitialForm, setSignupInitialForm] = React.useState({} as SignupInitialFormType);
  const [signupData, setSignupData] = React.useState<SignupData>({} as SignupData);
  const [isClientDefined, setIsClientDefined] = React.useState<boolean>(false);

  function handleClientChange(value: ClientType) {
    setClient(value);
  }

  function handleHideClientForm(value: boolean) {
    setHideClientForm(value);
  }

  function handleSignupInitialFormChange(data: SignupInitialFormType) {
    setSignupInitialForm({ ...data });
  }

  function handleSignupData(data: SignupDataApiReturn) {
    setSignupData({...signupData, ...data})
  }

  function clearSignupData(){
    setSignupData({} as SignupData)
  }

  function clearSignupInitialForm() {
    setSignupInitialForm({} as SignupInitialFormType);
  }

  function defineClient() {
    setIsClientDefined(true)
  }

  return (
    <AppContext.Provider
      value={{
        hideClientForm,
        client,
        signupInitialForm,
        handleClientChange,
        handleHideClientForm,
        handleSignupInitialFormChange,
        clearSignupInitialForm,
        isClientDefined,
        defineClient,
        signupData,
        handleSignupData,
        clearSignupData
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => React.useContext(AppContext);
