import { DefaultFormPropsType } from "global/types/forms.type";
import * as yup from "yup";


export interface FormData {
  validationToken: string;
}

export interface FormDataPropsType extends DefaultFormPropsType<FormData> {
  resendToken:any
}

export const schema = yup.object<FormData>().shape({
  validationToken: yup.string().required("Código é obrigatório").min(6, "O código deve conter 6 digitos"),
})