export function maskCPF(cpf: string = "", hide?: boolean) {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  if (hide) {
    let documentSplited = cpf.split(".");
    return (
      "***." +
      documentSplited[1] +
      "." +
      documentSplited[2].split("-")[0] +
      "-**"
    );
  }

  return cpf;
}

export function maskCNPJ(cnpj: string = "", hide?: boolean) {
  cnpj = cnpj.replace(/\D/g, "");

  cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");

  if (hide) {
    let documentSplited = cnpj.split(".");
    return (
      "***." +
      documentSplited[1] +
      "." +
      documentSplited[2].split("-")[0] +
      "-**"
    );
  }

  return cnpj;
}

export function maskPhone(phone: string = "", hide?: boolean) {
  phone = phone.replace(/\D/g, "");

  phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");

  return phone;
}

export function maskOnlyNumbers(numbers: string = "") {
  numbers = numbers.replace(/[^0-9]/g, "");
  return numbers;
}
