export const setTokenBanking = (token: string) => {
  localStorage.setItem('@tokenBanking', token);
};

export const getTokenBanking = () => {
  return localStorage.getItem('@tokenBanking')
};

export const setTokenLogin = (token: string) => {
  localStorage.setItem('@tokenLogin', token);
};

export const getTokenLogin = () => {
  return localStorage.getItem('@tokenLogin')
};

export const setTokenGoPoints = (token: string) => {
  localStorage.setItem('@tokenGoPoints', token);
};

export const getTokenGoPoints = () => {
  return localStorage.getItem('@tokenGoPoints')
};
