import { useEffect, useState } from "react";

const useResendToken = (countdownTime:number) => {

  const [loaderResend, setLoaderResend] = useState(false);
  const [labelResendButton, setLabelResendButton] = useState(`Código enviado! Tente novamente em ${countdownTime} segundos...`);
  const [intervalActive, setIntervalActive] = useState(false);
  const [activeTimer, setActiveTimer] = useState(true);
  let interval: any = null;

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (activeTimer) {
      if (intervalActive) {
        return;
      }
      startCountdown(countdownTime);
    }
  }, [activeTimer]);

  const handleLoaderResend = (active:boolean, resetLabel?: boolean) => {
    if(active){
        setLoaderResend(true);
        setLabelResendButton("Enviando código...");
    }else{
        setLoaderResend(false);
        if(resetLabel) setLabelResendButton("Reenviar código");
    }
  }

  const startCountdown = (seconds: number) => {
    
    let counter = seconds;
    setIntervalActive(true);

    interval = setInterval(() => {
      counter--;
      setLabelResendButton(`Código enviado! Tente novamente em ${counter} segundos...`)
      if (counter <= 0) {
        clearInterval(interval);
        setIntervalActive(false);
        setActiveTimer(false);
        setLabelResendButton("Reenviar código");
      }
    }, 1000);

  };

  return {
    handleLoaderResend,
    setActiveTimer,
    loaderResend,
    labelResendButton,
    activeTimer
  }

}

export default useResendToken;

