import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";
import { registerNewPassword } from "services/user";

const ForgotPasswordRegister = () => {
  const [loader, setLoader] = useState(false);
  const {client, isClientDefined, signupData, clearSignupData, clearSignupInitialForm} = useAppContext();
  const navigate = useNavigate();

  const { control, formState: { errors }, handleSubmit} = useForm<FormDataPropsType>({resolver: yupResolver(schema)});

  useEffect(() => {

    if(!isClientDefined) navigate("/")

  }, [])
  
  async function submitForm(data: FormDataPropsType) {
    
    setLoader(true);

    if(!await registerNewPassword(client, signupData.requestId, signupData.token, data.newPassword)){ setLoader(false); return; }

    clearSignupInitialForm();
    clearSignupData();

    setLoader(false);

    navigate("/dialog-page", {state:{
      type: "success",
      title: "Tudo certo!",
      subTitle: "Sua senha foi criada com sucesso",
      buttonText: "Entrar no site",
      routeTo: "/"
    }})
    
  }

  function handleBackButton() {
    navigate("/forgot-password-initial");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Esqueceu a senha?"
        subTitle="Tudo bem! Vamos gerar uma nova."
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader},
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default ForgotPasswordRegister;

