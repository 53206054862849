import { AxiosRequestConfig } from "./config/services.types";
import { toast } from "react-toastify";
import { getTokenBanking } from "utilities/localStorage";
import { requester } from "./config/requester"
import { getRequestParameters } from "./config/services.parameters";
import { ClientType } from "global/types/application.type";

export const fetchPageGoPoints = async (client: ClientType, document: string) => {
  
  const service: AxiosRequestConfig = {
    method: 'POST',
    baseURL: getRequestParameters(client).bankingParameters.baseUrlV2,
    url: 'bib-points-engine/getToken',
    headers: {
      Authorization: getTokenBanking(),
      'api_key': getRequestParameters(client).bankingParameters.apiKey
    },
    data: {
      fid: getRequestParameters(client).bankingParameters.fid,
      document,
    }
  }

  try {
    
    const { status, data } = await requester(service);

    if (status === 200) {
      const { mensagem_retorno } = data
      return mensagem_retorno.token
    }

    return false

  } catch (error: any) {

    toast.error('Não foi possível obter acesso a Go Points, por favor tente novamente mais tarde.')
    return false

  }

}