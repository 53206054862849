import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";
import { patchSignupUser, registerPassword } from "services/user";
import { signIn } from "services/authenticate";
import { maskOnlyNumbers } from "utilities/Mask";

const SignupPassword = () => {
  const [loader, setLoader] = useState(false);
  const {client, isClientDefined, signupData, clearSignupData, clearSignupInitialForm, signupInitialForm} = useAppContext();
  const navigate = useNavigate();

  const { control, formState: { errors }, handleSubmit} = useForm<FormDataPropsType>({resolver: yupResolver(schema)});

  useEffect(() => {

    if(!isClientDefined) navigate("/")

  }, [])
  
  async function submitForm(data: FormDataPropsType) {
    
    setLoader(true);

    const {userId, token, requestId} = signupData;

    if(!await registerPassword(client, userId, requestId, token, data.newPassword)) { setLoader(false); return; }

    const auth = await signIn(client, maskOnlyNumbers(signupInitialForm.cnpj), data.newPassword);
    if(!auth){ setLoader(false); return; }

    const patch = await patchSignupUser(client, signupData.accountId, signupInitialForm.cardName);
    if(!patch){ setLoader(false); return; }

    setLoader(false);

    clearSignupInitialForm();
    clearSignupData();

    navigate("/dialog-page", {state:{
      type: "success",
      title: "Tudo certo!",
      subTitle: "Seu cadastro foi realizado com sucesso!",
      buttonText: "Entrar no site",
      routeTo: "/"
    }})

  }

  function handleBackButton() {
    navigate("/")
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Cadastro"
        subTitle="Bem-vindo ao BIB Rewards"
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader},
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default SignupPassword;
