import Select from "components/inputs/InputSelect";
import { ClientType } from "global/types/application.type";
import { Button, Subtitle, TextButton } from "../styles";

type ClientProps = {
  states: {
    client: string;
    loading: boolean;
    listOptions: string[];
  };
  handlers: {
    setClient: (value: ClientType) => void;
    handleSubmitClient: () => any;
    handleSignupClient: () => any;
    handleNavigateForgotPassword: () => void;
  };
};

const Client = ({
  states: { client, loading, listOptions },
  handlers: {
    setClient,
    handleSubmitClient,
    handleSignupClient,
    handleNavigateForgotPassword,
  },
}: ClientProps) => {
  return (
    <>
      <Select
        label="Selecione o cliente"
        input={{
          disabled: false,
          value: client,
          autoFocus: true,
          change: setClient,
        }}
        options={listOptions}
      />

      <Button onClick={handleSubmitClient} disabled={loading}>
        {loading ? "Carregando..." : "Realizar Login"}
      </Button>

      {client === "Bib Corporate" && (
        <Button onClick={handleSignupClient} variant="secondary">
          Realizar cadastro
        </Button>
      )}

      {client === "Bib Corporate" ? (
        <TextButton onClick={handleNavigateForgotPassword}>
          Esqueci minha senha
        </TextButton>
      ) : (
        <Subtitle>Mude sua senha através do APP</Subtitle>
      )}
    </>
  );
};

export default Client;
