import GeneralStyle from './general.styles';
import ResetStyle from './reset.styles';

const GlobalStyles = () => {
  return (
    <>
      <ResetStyle />
      <GeneralStyle />
    </>
  );
};

export default GlobalStyles;
