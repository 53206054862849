import successImage from "assets/lock-check.svg";
import logo from "assets/logo.png";
import Button from "components/Buttons/Button";
import Subtitle from "components/Labels/Subtitle";
import Title from "components/Labels/Title";
import { FontSize } from "global/styles/sizes.styles";
import { Header, ImageLogo, Main } from "pages/initial/styles";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FormCardTextContainer,
  SuccessContainer,
  SuccessContent,
} from "../signup/styles";

const DialogPage = () => {

  const navigate = useNavigate();

  const {state} = useLocation();

  return (
    <Main>
      <Header>
        <ImageLogo src={logo} alt="logo do bib" />
      </Header>
      <SuccessContent>
        <SuccessContainer>
          <ImageLogo src={successImage} alt="Imagem de successo no cadastro" />
          <FormCardTextContainer style={{ textAlign: "center" }}>
            <Title text={state.title} />
            <Subtitle
              style={{ fontSize: FontSize.large }}
              text={state.subTitle}
            />
          </FormCardTextContainer>
          <Button
            style={{ minWidth: 250, maxWidth: "40%", marginTop: "4%" }}
            title={state.buttonText}
            onClick={() => {navigate(state.routeTo)}}
          />
        </SuccessContainer>
      </SuccessContent>
    </Main>
  );
};

export default DialogPage;
