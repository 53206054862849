import { AxiosRequestConfig } from "./config/services.types";
import { toast } from "react-toastify"
import { getTokenBanking, setTokenBanking, setTokenLogin } from "utilities/localStorage"
import { getRequestParameters } from "./config/services.parameters";
import { requester } from "./config/requester"
import { ClientType } from "global/types/application.type";

export const fetchTokenBanking = async (client: ClientType) => {

  const service: AxiosRequestConfig = {
    method: 'GET',
    baseURL: getRequestParameters(client).bankingParameters.baseUrl,
    url: 'authorizers/jwt-token',
    headers: {
      'Content-Type': 'application/json',
      'api_key': getRequestParameters(client).bankingParameters.apiKey
    }
  }

  try {
    
    const { data } = await requester(service);
    setTokenBanking(data.token);

  } catch (error: any) {
    
    toast.error('Opss... Algo deu errado!');
    return false;

  }
}

export const signIn = async (client: ClientType, username: string, password: string) => {
  
  const service: AxiosRequestConfig = {
    method: 'POST',
    baseURL: getRequestParameters(client).bankingParameters.baseUrl,
    url: 'users/sign-in',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getTokenBanking(),
    },
    data: {
      username,
      password,
    }
  }

  try {
    
    const { data } = await requester(service);
    setTokenLogin(data.token);
    return true;

  } catch (error: any) {
    
    if (error.response.status === 401) {
      toast.error('Usuário ou senha inválidos, entre com as informações corretas.');
      return false;
    }

    toast.error('Opss... Algo deu errado!');
    return false;

  }
  
}

export const fetchSensidiaAccessToken = async (client: ClientType) => {

  const service: AxiosRequestConfig = {
    method: 'POST',
    baseURL: getRequestParameters(client).sensidiaParameters.baseUrl,
    url: 'oauth/v3/access-token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': getRequestParameters(client).sensidiaParameters.apiKey,
      'client_id': getRequestParameters(client).sensidiaParameters.clientId,
    },
    data: 'grant_type=client_credentials',
  }

  try {
    
    const { data } = await requester(service);
    return data.access_token;
    
  } catch {

    toast.error('Opss... Algo deu errado!');
    return false;

  }

}