import { deviceSize } from "global/styles/sizes.styles";
import { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface ButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loader?: boolean;
  variant?: "secondary";
}

const Button = (props: ButtonPropsType) => {
  const { title, loader, variant = undefined, ...rest } = props;
  return (
    <ButtonContainer variant={variant} {...rest}>
      {loader ? "Carregando..." : title}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button<{ variant?: "secondary" }>`
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  height: 54px;
  background-color: ${({ variant }) =>
    variant === "secondary" ? "transparent" : "#000"};
  color: ${({ variant }) => (variant === "secondary" ? "#000" : "#fff")};
  ${({ variant }) =>
    variant === "secondary" &&
    css`
      border: 1px solid #000;
    `}

  @media(max-width: ${deviceSize.lgMobile2}px) {
    margin-top: 24px;
  }

  @media (max-width: ${deviceSize.xlgMobile}px) {
    height: 42px;
  }
`;

export default Button;
