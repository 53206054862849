import { DeviceSizeType } from 'global/types/application.type';

export const deviceSize: DeviceSizeType = {
  smMobile: 320,
  mdMobile: 375,
  lgMobile: 414,
  lgMobile2: 450,
  xlgMobile: 480,
  xlgMobile2: 650,
  tablet: 768,
  lgTablet2: 900,
  desk: 1024,
  lgDesk: 1160,
  hdDesk: 1280,
  wxgaDesk: 1366,
  wxgaplusDesk: 1440,
  hdpDesk: 1600,
  fullHDDesk: 1920,
  QHDDesk: 2560,
};

export const FontSize = {
  xsmall: '8px',
  smaller: '10px',
  small: '12px',
  small_00: '13px',
  medium: '14px',
  medium_00: '15px',
  large: '16px',
  large_000: '17px',
  large_00: '18px',
  large_01: '22px',
  large_02: '32px',
  larger: '42px',
  larger_01: '52px',
  xlarge: '72px',
};
