import logo from "assets/logo.png";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchTokenBanking, signIn } from "services/authenticate";
import { getRequestParameters } from "services/config/services.parameters";
import { fetchPageGoPoints } from "services/goPoints";
import { maskOnlyNumbers } from "utilities/Mask";
import Client from "./client";
import Signin from "./signin";
import {
  Content,
  Form,
  Header,
  IamgeBackground,
  ImageLogo,
  Main,
  Title,
} from "./styles";

const Login = () => {
  const { client, handleClientChange, hideClientForm, handleHideClientForm, defineClient, clearSignupData, clearSignupInitialForm } = useAppContext();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const isDisabled = username.length < (client === "Bib Corporate" ? 18 : 14) || (password.length < 8 || password.length > 12);

  const listOptions = ["Bib Infinity", "Bib Bahamas", "Bib Corporate"];

  const handleNavigateForgotPassword = () => {
    navigate("/forgot-password-initial");
  };

  const handleSubmitClient = async () => {
    setLoading(true);
    await fetchTokenBanking(client);
    handleHideClientForm(false);
    setLoading(false);
  };

  const handleSignupClient = () => {
    navigate("/signup-initial");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const auth = await signIn(client, maskOnlyNumbers(username), password);

    if (auth) {
      const tokenGoPoints = await fetchPageGoPoints(client, maskOnlyNumbers(username));

      if (typeof tokenGoPoints === "boolean") {
        return setLoading(false);
      }

      window.location.href = `${getRequestParameters(client).goPointsParameters.baseUrl}?token=${tokenGoPoints}`;
      setLoading(false);
      clearForm();
    }

    setLoading(false);
  };

  const clearForm = () => {
    handleClientChange("Bib Infinity");
    setUsername("");
    setPassword("");
  };

  useEffect(() => {
    clearSignupInitialForm();
    clearSignupData();
    defineClient();
  }, [])

  return (
    <Main>
      <Header>
        <ImageLogo src={logo} alt="logo do bib" />
      </Header>

      <Content>
        <IamgeBackground />
        <Form>
          <Title>BIB Rewards</Title>

          {hideClientForm ? (
            <Client
              states={{
                client,
                loading,
                listOptions,
              }}
              handlers={{
                setClient: handleClientChange,
                handleSubmitClient,
                handleSignupClient,
                handleNavigateForgotPassword,
              }}
            />
          ) : (
            <Signin
              states={{
                username,
                password,
                loading,
                isDisabled,
                hideForm: hideClientForm,
                client
              }}
              handlers={{
                handleSubmit,
                setPassword,
                setUsername,
                setHideForm: handleHideClientForm,
                handleNavigateForgotPassword
              }}
            />
          )}
        </Form>
      </Content>
    </Main>
  );
};

export default Login;
