import icon from "assets/arrow-left.svg";
import InputText from "components/inputs/InputText";
import { maskCNPJ, maskCPF } from "utilities/Mask";
import { Button, Icon, Subtitle, TextButton } from "../styles";

type SigninProps = {
  states: {
    username: string;
    password: string;
    loading: boolean;
    isDisabled: boolean;
    hideForm: boolean;
    client: "Bib Infinity" | "Bib Bahamas" | "Bib Corporate"
  };
  handlers: {
    setUsername: React.Dispatch<React.SetStateAction<string>>;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    setHideForm: (value: boolean) => void;
    handleSubmit: () => any;
    handleNavigateForgotPassword: () => void;
  };
};

const Signin = ({
  states: { username, password, loading, isDisabled, hideForm, client },
  handlers: { setUsername, setPassword, handleSubmit, setHideForm, handleNavigateForgotPassword },
}: SigninProps) => {
  return (
    <>
      <Icon onClick={() => setHideForm(!hideForm)}>
        <img src={icon} alt="Icon em formato de seta para voltar" />
      </Icon>
      <InputText
        label={client === "Bib Corporate" ? "CNPJ" : "CPF"}
        input={{
          disabled: false,
          mask: client === "Bib Corporate" ? maskCNPJ : maskCPF,
          value: username,
          autoFocus: true,
          change: setUsername,
          maxLength: client === "Bib Corporate" ? 18 : 14,
          placeholder: client === "Bib Corporate" ? "00.000.000/0000-00" : "000.000.000-00",
        }}
      />
      <InputText
        label="Senha"
        input={{
          disabled: false,
          value: password,
          change: setPassword,
          type: "password",
          allowShowPassword: true,
          placeholder: "*******",
        }}
      />

      <Button onClick={handleSubmit} disabled={isDisabled || loading}>
        {loading ? "Carregando..." : "Entrar no site"}
      </Button>

      {client === "Bib Corporate" ? (
        <TextButton onClick={handleNavigateForgotPassword}>
          Esqueci minha senha
        </TextButton>
      ) : (
        <Subtitle>Mude sua senha através do APP</Subtitle>
      )}
    </>
  );
};

export default Signin;
