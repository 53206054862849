import { deviceSize } from "global/styles/sizes.styles";
import styled from "styled-components";

export const SuccessContent = styled.div`
  width: 100%;
  height: 90%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
`

export const FormCardTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  @media (max-width: ${deviceSize.tablet}px) {
    margin: 8% 0;
    align-items: center;
  }
`

export const SuccessContainer = styled.div`
  display: flex;
  background-color: white;
  border-radius: 30px;
  width: 100%;
  padding: 8% 6%;
  justify-content: center;
  min-height: 50%;
  flex-direction: column;
  align-items: center;
`