export const isValidCPF = (strCPF: string) => {
  strCPF = validateOnlyNumber(strCPF);

  let sum;
  let rest;
  sum = 0;

  if (strCPF === "00000000000") {
    return false;
  }
  if (strCPF === "11111111111") {
    return false;
  }

  if (strCPF === "22222222222") {
    return false;
  }

  if (strCPF === "33333333333") {
    return false;
  }

  if (strCPF === "44444444444") {
    return false;
  }

  if (strCPF === "55555555555") {
    return false;
  }

  if (strCPF === "66666666666") {
    return false;
  }

  if (strCPF === "77777777777") {
    return false;
  }

  if (strCPF === "88888888888") {
    return false;
  }

  if (strCPF === "99999999999") {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(strCPF.substring(10, 11))) {
    return false;
  }
  return true;
};

export function validateOnlyNumber(text: string) {
  if (!text) {
    console.log(text.replace(/\D/g, ""));
    return text;
  }

  return text.replace(/\D/g, "");
}

export function isEmptyString(str: string) {
  return !str || str.length === 0;
}

export function validateCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  const digits = cnpj.split("").map(Number);

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += digits[i] * (i < 4 ? 5 - i : 13 - i);
  }
  let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (digits[12] !== digit) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 13; i++) {
    sum += digits[i] * (i < 5 ? 6 - i : 14 - i);
  }
  digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (digits[13] !== digit) {
    return false;
  }

  return true;
}
