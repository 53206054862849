import Button from "components/Buttons/Button";
import Subtitle from "components/Labels/Subtitle";
import Title from "components/Labels/Title";
import { FontSize, deviceSize } from "global/styles/sizes.styles";
import styled from "styled-components";

interface FormLayoutProps {
  children: JSX.Element;
  title: string;
  subTitle: string;
  buttons: {
    submitButton: {
      title?: string;
      variant?: "secondary";
      handleSubmit: (anything?: any) => any;
      loader?: boolean;
    };
    backButton: {
      title?: string;
      variant?: "secondary";
      handleBackButton: () => any;
    };
  };
}

const FormLayout = ({
  buttons,
  children,
  subTitle,
  title,
}: FormLayoutProps) => {
  return (
    <FormContent>
      <FormCard>
        <FormCardTextContainer>
          <Title text={title} />
          <Subtitle text={subTitle} />
        </FormCardTextContainer>
        <FormCardContent>{children}</FormCardContent>
      </FormCard>
      <ButtonsContainer>
        <Button
          variant={buttons.backButton.variant}
          title={buttons.backButton.title || "Voltar"}
          onClick={buttons.backButton.handleBackButton}
        />
        <Button
          loader={buttons.submitButton.loader}
          variant={buttons.submitButton.variant}
          title={buttons.submitButton.title || "Avançar"}
          onClick={buttons.submitButton.handleSubmit}
        />
      </ButtonsContainer>
    </FormContent>
  );
};

const FormContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormCard = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 6% 6%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.small_00};
    border-radius: 20px 20px 0 0;
  }
`;

const FormCardTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: ${deviceSize.tablet}px) {
    align-items: center;
  }
`;

const FormCardContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.small_00};
    flex-direction: column;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5%;
  background-color: white;
  border-radius: 0 0 20px 20px;
  padding: 6% 6% 10% 6%;

  @media (min-width: 770px) {
    gap: 40%;
    margin-top: 40px;
    background-color: transparent;
    padding: 0;
  }
`;

export default FormLayout;
