import { AxiosRequestConfig } from "./config/services.types";
import { requester } from "./config/requester";
import { toast } from "react-toastify";
import { getRequestParameters } from "./config/services.parameters";
import { ClientType } from "global/types/application.type";

export const getCardByDocument = async (client: ClientType, documentNumber: string, accessToken: string) => {
  
  const service: AxiosRequestConfig = {
    method: 'GET',
    baseURL: getRequestParameters(client).sensidiaParameters.baseUrl,
    url: `cards/v2/document/${documentNumber}`,
    headers: {
      'Content-Type': 'application/json',
      'access_token': accessToken,
      'client_id': getRequestParameters(client).sensidiaParameters.clientId,
      'org': getRequestParameters(client).sensidiaParameters.org,
    },
  };

  try {
    
    const { data } = await requester(service);
    return data.cardsResult;
    
  } catch {
    toast.error('Opss.. Erro ao cadastrar o cartão. Não foi possível validar o seu cartão.');
    return false;
  }
  
}
