import { BrowserRouter, Route, Routes } from "react-router-dom";

import ForgotPasswordRegister from "pages/forgotPassword/forgotPasswordRegister";
import ForgotPasswordCode from "pages/forgotPassword/forgotPasswordCode";
import ForgotPasswordInitital from "pages/forgotPassword/forgotPasswordInitial";
import Initial from "pages/initial";
import SignupInitial from "pages/signup/signupInitial";
import SignupToken from "pages/signup/signupToken";
import SignupPassword from "pages/signup/signupPassword";
import DialogPage from "pages/dialogs";

export const routePaths = {
  initial: "/",
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Initial />} />
        <Route path="/signup-initial" element={<SignupInitial />} />
        <Route path="/signup-password" element={<SignupPassword />} />
        <Route path="/signup-token" element={<SignupToken />} />
        
        <Route
          path="/forgot-password-initial"
          element={<ForgotPasswordInitital />}
        />
        <Route
          path="/forgot-password-code"
          element={<ForgotPasswordCode />}
        />
        <Route
          path="/forgot-password-register"
          element={<ForgotPasswordRegister />}
        />
        <Route path="/dialog-page" element={<DialogPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
