import { ClientType } from "global/types/application.type";
import { IClientParameters } from "./services.types";

const apiKeySelector = {
    'Bib Infinity': process.env.REACT_APP_API_KEY_INFINITY,
    'Bib Bahamas': process.env.REACT_APP_API_KEY_BAHAMAS,
    'Bib Corporate': process.env.REACT_APP_API_KEY_CORPORATE
}
  
const fidSelector = {
    'Bib Infinity': process.env.REACT_APP_FID_INFINITY,
    'Bib Bahamas': process.env.REACT_APP_FID_BAHAMAS,
    'Bib Corporate': process.env.REACT_APP_FID_CORPORATE
}

export const getRequestParameters = (client:ClientType) => ({
  
    bankingParameters: {
        baseUrl: process.env.REACT_APP_BASE_URL_BANKING_V1,
        baseUrlV2: process.env.REACT_APP_BASE_URL_BANKING_V2,
        apiKey: apiKeySelector[client],
        fid: fidSelector[client]
    },
    orbitallParameters: {
        baseUrl: process.env.REACT_APP_BASE_URL_ORBITALL,
        apiKey: process.env.REACT_APP_API_KEY_ORBITALL,
    },
    sensidiaParameters: {
        baseUrl: process.env.REACT_APP_SENSIDIA_BASE_URL,
        apiKey: `Basic ${process.env.REACT_APP_API_KEY_SENSIDIA}`,
        clientId: process.env.REACT_APP_SENSIDIA_CLIENT_ID,
        org: 71,
    },
    goPointsParameters: {
        baseUrl: process.env.REACT_APP_BASE_URL_GO_POINTS
    }

} as IClientParameters);