import { FontSize } from "global/styles/sizes.styles";
import React from "react";
import styled from "styled-components";

interface TitlePropsType {
  text: string;
  style?: React.CSSProperties;
}

const Title = (props: TitlePropsType) => {
  const { text, style } = props;

  return <TitleContainer style={style}>{text}</TitleContainer>;
};

export const TitleContainer = styled.h1`
  font-size: ${FontSize.large_02};
  font-weight: 400;
`;

export default Title;
