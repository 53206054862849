import { DefaultFormPropsType } from "global/types/forms.type";
import * as yup from 'yup';

export type FormDataPropsType = {
  newPassword: string
  newPasswordConfirm: string;
}

export interface FormPropsType extends DefaultFormPropsType<FormDataPropsType> {}

export const schema = yup.object<FormDataPropsType>().shape({
  newPassword: yup.string()
    .required("Nova senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 8 caracteres")
    .max(12, "A senha deve ter no máximo 12 caracteres")
    .matches(/[A-Za-z]/g, "A senha deve conter uma letra")
    .matches(/[0-9]/g, "A senha deve conter um número")
    .test("Sequences", "A senha não pode conter sequências", (value) => !value.includes("abc") && !value.includes("cba") && !value.includes("123") && !value.includes("321")),
  newPasswordConfirm: yup.string()
    .required("Confirmação de senha é obrigatória")
    .oneOf([yup.ref('newPassword')], "As senhas não coincidem")
})