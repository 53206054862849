import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { fetchSensidiaAccessToken, fetchTokenBanking } from "services/authenticate";
import { getCardByDocument } from "services/cards";
import { validateOnlyNumber } from "utilities/Validators";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";
import { toast } from "react-toastify";
import { cardsResultItemResponse } from "../../../services/config/services.types";
import { firstLogin, signUpUser, verifyDocument } from "services/user";
import { getRequestParameters } from "services/config/services.parameters";

const SignupInitial = () => {
  
  const [loader, setLoader] = useState(false);
  const { handleSignupInitialFormChange, signupInitialForm, handleSignupData, client, isClientDefined, clearSignupData} = useAppContext();
  const navigate = useNavigate();

  const { control, formState: { errors }, handleSubmit, setValue} = useForm<FormDataPropsType>({resolver: yupResolver(schema)});

  useEffect(() => {

    if(!isClientDefined) navigate("/");

    setValue("cnpj", signupInitialForm.cnpj);
    setValue("cardName", signupInitialForm.cardName);
    setValue("email", signupInitialForm.email);
    setValue("cellphone", signupInitialForm.cellphone);
    setValue("last4Digits", signupInitialForm.last4Digits);

    fetchTokenBanking(client);
    clearSignupData();

  }, [])
  
  async function submitForm(data: FormDataPropsType) {

    setLoader(true);

    handleSignupInitialFormChange(data);

    const checkAccount = await verifyDocument(client, validateOnlyNumber(data.cnpj));
    if(!checkAccount) { setLoader(false); return; }

    if(checkAccount.accountId){

      const checkFirstLogin = await firstLogin(client, checkAccount.accountId);
      if(!checkFirstLogin) { setLoader(false); return; }

    }

    const accessToken = await fetchSensidiaAccessToken(client);
    if(!accessToken) { setLoader(false); return; } 

    const cards = await getCardByDocument(client, validateOnlyNumber(data.cnpj), accessToken!);
    if(!cards) { setLoader(false); return; } 

    if(cards!.find((card: cardsResultItemResponse) => Number(card.numOrg) === getRequestParameters(client).sensidiaParameters.org && card.last4Digits === String(data.last4Digits))){
      
      const {id, account} = await signUpUser(client, data.cnpj, data.cellphone, data.email, data.cardName);
      if(!id) { setLoader(false); return; }
      
      handleSignupData({userId: id, accountId: account.id});
      navigate("/signup-token");
    
    } else {

      toast.error('Opss.. Erro ao cadastrar o cartão. Não foi possível validar o seu cartão.')
      setLoader(false); return;

    }

  }

  function handleBackButton() {
    navigate("/");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Cadastro"
        subTitle="Bem-vindo ao BIB Rewards"
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default SignupInitial;
