import { DefaultFormPropsType } from "global/types/forms.type";
import * as yup from 'yup';

export type FormDataPropsType = {
  validationCode: string;
}

export interface FormPropsType extends DefaultFormPropsType<FormDataPropsType> {
  resendToken:any
}

export const schema = yup.object<FormDataPropsType>().shape({
  validationCode: yup.string().required("Código é obrigatório").min(6, "O código deve conter 6 digitos"),
})