import logo from "assets/logo.png";
import { deviceSize } from "global/styles/sizes.styles";
import styled from "styled-components";

const DefaultLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <Main>
      <Header>
        <ImageLogo src={logo} />
      </Header>
      <Content>{children}</Content>
    </Main>
  );
};


const Main = styled.main`
width: 100%;

@media (max-width: ${deviceSize.lgMobile2}px) {
  background-color: #000;
  min-height: 100vh;
}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3% 10%;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  background: #000;
  height: 12vh;
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

const ImageLogo = styled.img`
  width: 170px;
`;

export default DefaultLayout;
