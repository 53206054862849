import { FontSize } from "global/styles/sizes.styles";
import styled from "styled-components";

interface SubtitlePropsType {
  text: string;
  style?: React.CSSProperties;
}

const Subtitle = (props: SubtitlePropsType) => {
  const { text, style } = props;
  return <SubtitleContainer style={style}>{text}</SubtitleContainer>;
};

export const SubtitleContainer = styled.p`
  font-size: ${FontSize.medium};
`;

export default Subtitle;
