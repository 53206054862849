import { FontSize, deviceSize } from "global/styles/sizes.styles";
import styled from "styled-components";

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 42px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.small_00};
    flex-direction: column;
    gap: 0;
  }
`