import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Form from "./form/Form";
import { FormData, schema } from "./form/schema";
import { forgetPassword } from "services/user";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { fetchTokenBanking } from "services/authenticate";

const ForgotPasswordInitital = () => {
  
  const [loader, setLoader] = useState(false);
  const { control, formState: { errors }, handleSubmit} = useForm<FormData>({resolver: yupResolver(schema)});
  const { client, handleSignupData, clearSignupData, isClientDefined, handleSignupInitialFormChange } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {

    if(!isClientDefined) navigate("/")
    fetchTokenBanking(client);
    clearSignupData();
    
  }, [])

  async function submitForm(data: FormData) {

    setLoader(true);

    handleSignupInitialFormChange({cnpj: data.cnpj});

    const {requestId} = await forgetPassword(client, data.cnpj);
    if(!requestId) { setLoader(false); return; } 

    handleSignupData({requestId});

    setLoader(false);

    navigate("/forgot-password-code");
    
  }

  function handleBackButton() {
    navigate("/");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Esqueceu a senha?"
        subTitle="Tudo bem! Vamos gerar uma nova."
        buttons={{
          backButton: {
            variant: "secondary",
            handleBackButton: handleBackButton,
          },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader},
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default ForgotPasswordInitital;
