import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useForm } from "react-hook-form";
import Form from "./form/Form";
import { FormData, schema } from "./form/schema";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "context/AppContext";
import { forgetPassword, forgetPasswordConfirm } from "services/user";
import useResendToken from "hooks/useResendToken";

const ForgotPasswordCode = () => {
  
  const [loader, setLoader] = useState(false);
  const { control, formState: { errors }, handleSubmit} = useForm<FormData>({resolver: yupResolver(schema)});
  const { client, handleSignupData, signupInitialForm, signupData, isClientDefined } = useAppContext();
  
  const navigate = useNavigate();

  useEffect(() => {
    
    if(!isClientDefined) navigate("/")

  }, [])

  async function submitForm(data: FormData) {

    setLoader(true);
    
    const { token, requestId } = await forgetPasswordConfirm(client, signupData.requestId, data.validationToken);
    if(!token) { setLoader(false); return; } 

    handleSignupData({token, requestId});
    setLoader(false);
    navigate("/forgot-password-register");

  }

  const {
    handleLoaderResend,
    setActiveTimer,
    loaderResend,
    labelResendButton,
    activeTimer
  } = useResendToken(30);
  
  async function handleResendToken(){
    
    handleLoaderResend(true);
    
    const {requestId} = await forgetPassword(client, signupInitialForm.cnpj);
    if(!requestId) { handleLoaderResend(false, true); return; } 

    handleSignupData({requestId});
    handleLoaderResend(false);
    setActiveTimer(true);

  }

  function handleBackButton() {
    navigate("/forgot-password-initial");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Esqueceu a senha?"
        subTitle="Tudo bem! Vamos gerar uma nova."
        buttons={{
          backButton: { handleBackButton: handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form control={control} errors={errors} resendToken={{handleResendToken, loaderResend, labelResendButton, activeTimer}} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default ForgotPasswordCode;
