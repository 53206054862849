import { FormRow } from "components/Layouts/FormLayoutStyles";
import InputText from "components/inputs/InputText";
import { Controller } from "react-hook-form";
import { FormDataPropsType } from "./schema";

const Form = ({ control, errors, resendToken }: FormDataPropsType) => {
  return (
    <>
      <FormRow>
        <Controller
          control={control}
          name="validationToken"
          render={({ field: { value, onChange } }) => (
            <InputText
              label="Código de validação"
              input={{
                value: value,
                change: onChange,
                maxLength: 6,
              }}
              errorMessage={errors.validationToken?.message}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <button 
          onClick={() => {resendToken.handleResendToken()}}
          disabled={resendToken.loaderResend || resendToken.activeTimer}
        >
          {resendToken.labelResendButton}
        </button>
      </FormRow>
    </>
  );
};

export default Form;
