/* eslint-disable react-hooks/exhaustive-deps */
import { InputHTMLAttributes, useEffect, useState } from "react";
import styled from "styled-components";
import eyeClosed from "assets/eye_closed.svg";
import eyeOpened from "assets/eye_opened.svg";
import { FontSize } from "global/styles/sizes.styles";

export interface InputPropsType {
  input: IptPropsType;
  label?: string;
  errorMessage?: string;
}

export interface IptPropsType extends InputHTMLAttributes<HTMLInputElement> {
  password?: boolean;
  mask?: Function;
  change: Function;
  allowShowPassword?: boolean;
}

const stateColors = {
  errorColor: "#cc2525",
  disabledColor: "#858585",
  normalColor: "#000",
};

const InputText = (props: InputPropsType) => {
  
  const { label, input, errorMessage } = props;
  const { mask, change, value, password, disabled, type, allowShowPassword = false, ...inputDefault } = input;

  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    try {
      if (mask) {
        onChangeInputText(mask(value));
      }
    } catch (error) {
      console.log(error);
    }
  }, [value]);

  const onChangeInputText = async (
    val: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    let handledValue = "";

    if (typeof val === "string") {
      handledValue = val;
    } else {
      handledValue = val.target.value;
    }
    if (mask) {
      handledValue = mask(handledValue);
    }
    change(handledValue);
  };

  return (
    <InputWrapper>
      <Label disabled={disabled} error={!!errorMessage}>
        {label}
      </Label>
      <Input
        error={!!errorMessage}
        onChange={(e) => onChangeInputText(e)}
        value={value}
        disabled={disabled}
        type={type === 'password' && passwordVisible ? "text" : type}
        {...inputDefault}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {allowShowPassword && 
        <button style={{position: 'absolute', right: 0, padding: 10}} onClick={() => {setPasswordVisible(!passwordVisible)}}>
          <img alt="Ver senha" src={passwordVisible ? eyeClosed : eyeOpened} style={passwordVisible ? {marginTop: 0} : {marginTop: 2}} />
        </button>}
    </InputWrapper>
  );
};

export default InputText;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative
`;

const Label = styled.span<{ error?: boolean; disabled?: boolean }>`
  color: ${({ error, disabled }) => {
    if (error) {
      return stateColors.errorColor;
    }
    if (disabled) {
      return stateColors.disabledColor;
    }

    return stateColors.normalColor;
  }};
`;

const ErrorMessage = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: ${FontSize.medium};
  color: #cc2525;
`;

const Input = styled.input<{ error?: boolean; disabled?: boolean }>`
  border: none;
  width: 100%;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-size: ${FontSize.medium};
  color: ${({ error, disabled }) => {
    if (error) {
      return stateColors.errorColor;
    }
    if (disabled) {
      return stateColors.disabledColor;
    }

    return stateColors.normalColor;
  }};
  padding: 8px 0;
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid
    ${({ error, disabled }) => {
      if (error) {
        return stateColors.errorColor;
      }
      if (disabled) {
        return stateColors.disabledColor;
      }

      return stateColors.normalColor;
    }};
  background: transparent;

  ::placeholder {
    color: #566068;
  }
`;
