import { FormRow } from "components/Layouts/FormLayoutStyles";
import InputText from "components/inputs/InputText";
import { Controller } from "react-hook-form";
import { FormPropsType } from "./schema";

export const Form = ({ control, errors }: FormPropsType) => {

  return (
    <>
      <FormRow>
        <Controller
            control={control}
            name="newPassword"
            render={({ field: { value, onChange } }) => (
              <InputText
                input={{
                  style: { textTransform: "capitalize" },
                  change: onChange,
                  value: value,
                  maxLength: 12,
                  type: 'password',
                  allowShowPassword: true
                }}
                label="Crie uma nova senha"
                errorMessage={errors.newPassword?.message}
              ></InputText>
            )}
        />
        <Controller
          control={control}
          name="newPasswordConfirm"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                change: onChange,
                value: value,
                maxLength: 12,
                type: 'password',
                allowShowPassword: true
              }}
              label="Confirme a senha criada"
              errorMessage={errors.newPasswordConfirm?.message}
            />
          )}
        />
      </FormRow>
      <p style={{ color: 'grey', fontSize: '15px', marginBottom: '4px', lineHeight: '18px' }}>
        A senha deve ter no mínimo uma letra e um número
        <br />
        A senha deve ter no mínimo 8 e no máximo 12 caracteres
        <br />
        Não é permitido o uso de sequências crescentes ou decrescentes. Ex: 123, abc, cba, 54321
      </p>
    </>
  );
};
