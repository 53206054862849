import { DefaultFormPropsType } from "global/types/forms.type";
import { validateCNPJ } from "utilities/Validators";
import * as yup from 'yup';

export type FormDataPropsType = {
  cnpj: string;
  cardName: string;
  email: string;
  cellphone: string;
  last4Digits: string
}

export interface FormPropsType extends DefaultFormPropsType<FormDataPropsType> {}

export const schema = yup.object<FormDataPropsType>().shape({
  cnpj: yup.string()
    .required('CNPJ é obrigatório')
    .min(18, "O CNPJ informado é inválido")
    .test('valid-cnpj', 'O CNPJ informado é inválido', (value) => validateCNPJ(value)),
  cardName: yup.string().required("Nome da empresa é obrigatório").min(3, "O nome da empresa precisa ter no mínino 3 caracteres"),
  email: yup.string().required("Email é obrigatório").email("O e-mail informado é inválido"),
  cellphone: yup.string()
    .required("Celular é obrigatório")
    .min(15, "O celular informado é inválido"),
  last4Digits: yup.string()
    .required("Os 4 últimos dígitos do cartão são obrigatórios")
    .min(4, "4 últimos dígitos do cartão são inválidos")
})